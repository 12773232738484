.vorstellung {
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  text-align: center;
  margin-top: 2rem;
  height: 100%;
  align-items: center!important;
  gap: 5rem;
  min-height: 1000px;

  .absatz {
    font-size: 1.4rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-self: center!important;


    &:last-child{

    }


    img {
      align-self: center;
    }

    p {
      color: $grey;
      opacity: .5;
      margin: 0.25rem;
      text-align: center;
      font-size: 1.4rem;
      max-width: 700px;
      align-self: center;
      display: inline;
    }

  }
}


@media only screen and (max-width: 700px) {
.vorstellung{
  .absatz{
    flex-direction: column;
    justify-content: center;

    img{
      margin-right: 0;
    }

    p{
      text-align: center;
      font-size: 1.5rem;
      margin-top: 1rem;
    }

  }
}


}
