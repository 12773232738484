.logo {
  position: absolute;
  top: 50%;
  left: 10%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  transform:translateY(-50%);

  img {
    width: 80%;
  }

  h1 {
    text-align: center;
    color: $darkgreen;

    span {
      color: $green;
      padding: 0.1rem;
    }
  }

  .button{
    width: 80%;
    border: 2px solid $green;
    text-align: center;
    padding: .7rem;
    margin: 1rem;
    color: $green;
    align-self: center;
    border-radius: 10px;

    .buttonText{
      font-size: 1.4rem;
    }
  }
}

@media only screen and (max-width: 1450px) {

  .logo{
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 70vh;
  }




}


@media only screen and (max-width: 700px) {

  .logo{
    top: 60%;
    width: 100%;


    .button{

      .buttonText{
        padding: .4rem;
        font-size: 1rem;
      }
    }
  }




}