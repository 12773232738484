.dreieck{
  clip-path: polygon(0 0, 100% 44%, 100% 0);
  background: $green;
  opacity: 0.3;
  width: 100%;
  height: 20vh;
  position: absolute;
}

.unten{
  bottom: 0;
  clip-path: polygon(0 46%, 0 100%, 100% 100%);
  height: 20vh;

}