.kategorie{
  margin-top: 3rem;
  display: flex;
  flex-direction: column;

  .produktreihe{
    display: flex;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 4rem;
    width: 80%;
    align-self: center;
  }

  h1{
    text-align: center;
    margin-top: 5rem;
    color: $green;
    background: rgba($green,0.4);
    width: 100%;
    padding: 1rem;
  }
}