.kategorien {
  align-self: center;

  width: calc(60% + 4rem);
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 2rem;
  overflow-x: scroll;
  overflow-y: scroll;

  .kategorie {

    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-decoration: none;


    .kreis {
      height: 10vw;
      width: 10vw;

      border: $green solid 1px;
      position: relative;
      margin: 0 1rem;
      border-radius: 100vw;

    }

    p {
      margin: 1rem;
      color: $green;
    }


  }
}


@media only screen and (max-width: 1200px) {

  .kategorien {
    justify-content: center;
    width: 100%;

    .kategorie {

      .kreis {
        height: 7rem;
        min-width: 7rem;
      }

    }
  }
}


@media only screen and (max-width: 700px) {

  .kategorien {
    justify-content: center;
    width: 100%;



    .kategorie {

      .kreis {
        height: 6rem;
       min-width: 6rem;
      }

    }
  }

}


