.navigation {

  position: absolute;
  z-index: 200;

  .navbutton {
    border-radius: 100%;
    height: 4rem;
    width: 4rem;
    background: transparent;
    position: fixed;
    padding: 1rem;
    border: 1px $green solid;
    right: 1.5rem;
    top: 10vh;

    svg {
      width: 100%;
      height: 100%;
      border-color: $green;
    }


    /*&:hover {
      transition: 2s ease-in-out;
      animation: navigationHover .5s ease-in-out;

    }*/

    &:active {
      transform: translate(1px, 3px);
    }
  }

  .navbutton2 {
    border-radius: 100%;
    height: 4rem;
    width: 4rem;
    background: transparent;
    position: absolute;
    padding: 1rem;
    border: 1px $green solid;
    right: 1.5rem;
    top: 10vh;



    svg {
      width: 100%;
      height: 100%;
      border-color: $green;
    }


    /*&:hover {
      transition: 2s ease-in-out;
      animation: navigationHover .5s ease-in-out;

    }*/

    &:active {
      transform: translate(1px, 3px);
    }
  }

  .navcontainer {
    position: fixed;
    height: 100vh;
    width: 50vw;
    max-width: 400px;
    background: white;
    right: -100%;
    top: 0;
    overflow: hidden;
    //clip-path: polygon(100% 0, 0 0, 100% 100%);
    transition: 0.8s;

    ul {
      display: flex;
      justify-content: center;
      flex-direction: column;
      height: 100%;
      width: 100%;

      li {
        list-style: none;
        width: 100%;
        margin: 1rem 0;

        align-self: center;
        padding: .7rem;
        position: relative;

        &::after{
          content: "";
          height: 30px;
          width: 80%;
          position: absolute;
          top: -23px;
          left: 50%;
          transform: translateX(-50%);
          color: $green;
          margin: 1rem 0;
          align-self: center;
          padding: .7rem;
          text-align: center;
          background: #78B73A;
          opacity: 0.5;
          z-index: -2;

        }

        a{
          position: absolute;
          text-align: center;
          left: 0;
          top: 50%;
          color: $grey;
          width: 100%;
          text-decoration: none;
        }
      }
    }
  }

}

.show{
  position: fixed;
  right: 0 !important;
}


@keyframes navigationHover {

  20% {
    transform: rotate(-20deg);
  }

  50% {
    transform: rotate(20deg);

  }

  75% {
    transform: rotate(0deg);

  }
  100% {
    transform: rotate(0deg);
  }

}

@media only screen and (max-width: 600px) {
  .navigation {
    .navcontainer{
      width: 100%;
      max-width: 100%;
    }
    .navbutton,.navbutton2 {
      top: 15vh;
      height: 3rem;
      width: 3rem;
      padding: 0.7rem;
      svg{
      }
    }
  }
}