.contactbar {
  z-index: 5000;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;

  .kontakt-header {
    height: 3rem;
    background-color: rgba($black, 0.9);

    ul {
      display: flex;
      justify-content: space-evenly;
      list-style: none;
      align-items: center;
      height: 100%;
      color: $green;
      //

      svg {
        align-self: center;
        margin: 0 1rem;
        vertical-align: -0.3em;
      }

    }
  }
}


@media only screen and (max-width: 1450px) {
  .contactbar {

    .kontakt-header {
      background-color: $black;
      height: fit-content;

      ul {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        list-style: none;
        align-items: center;
        height: 100%;
        color: $green;

        li {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin: 0.5rem 1rem;
        }

        svg {
          align-self: center;
          margin: 0 1rem;
          vertical-align: -0.3em;
        }

      }
    }
  }
}


@media only screen and (max-width: 700px) {

  .contactbar {

    .kontakt-header {
      ul {

        li {
          font-size: 0.8rem;
        }

        svg {
        }

      }
    }
  }

}

@media only screen and (max-width: 320px) {

  .contactbar {

    .kontakt-header {
      ul {

        li {
          font-size: 0.6rem;
        }

        svg {
        }

      }
    }
  }

}