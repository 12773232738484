.kontakt {
  background-size: cover;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: fit-content;
  padding: 2rem;
  width: 100%;
  background-color: $green;


  .kontakt_feld {
    max-width: 1300px;
    width: 100%;
    margin: .3rem;
    align-self: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    border: $green 1px solid;
    background-color: rgba(243, 243, 243, 0.90);




    h1 {
      color: $green;
      text-align: center;
      width: 100%;
      padding: 1rem;
    }

    .row {
      width: 80%;
      height: 80%;
      align-self: center;
      display: flex;
      justify-content: flex-start;
      flex-direction: row;


      .spalte1 {
        display: flex;
        justify-content: space-evenly;
        flex-direction: row;
        padding: 1rem;
        width: 100%;
        flex-wrap: wrap;


        .info {
          display: flex;
          justify-content: center;
          flex-direction: row;
          margin: 1rem 0;
          align-self: flex-start;

          img {
            height: 40px;
          }

          .text {
            margin-left: 1rem;

            .ueberschrift {
              color: $green;
              font-size: 1.5rem;
            }

            .information {
              color: $green;
              font-size: 1rem;
            }
          }
        }
      }
    }
  }


  hr {
    width: 80%;
    margin: 1rem;
    align-self: center;
    color: white;
  }

  .footertext {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
    color: $grey;
    align-self: center;
   background-color: rgba(243, 243, 243, 0.90);

    max-width: 1300px;
    width: 100%;
    padding-top: 2rem;
    padding-bottom: 2rem;


    details {
      width: 30%;
      transition: 0.5s ease-in-out;
    }

    summary {
      width: 40%;
      outline: none;
      padding: 1rem;
      display: block;
      color: white;
      padding-left: 2.2rem;
      position: relative;
      cursor: pointer;
    }

    details {
      box-sizing: border-box;
      margin-top: 5px;
      background: rgba($green, .9);
      //padding: 1rem;
      color: white;

      p {
        line-height: 1.4rem;
        margin-top: 0.2rem;
      }
    }

    .inhalt {
      padding: 1rem;
    }

    details summary::-webkit-details-marker {
      display: none;
      width: 100%;
    }

    details[open] > summary:before {
      transform: rotate(90deg);
    }

    summary:before {
      content: '';
      border-width: .4rem;
      border-style: solid;
      border-color: transparent transparent transparent #fff;
      position: absolute;
      top: 1.3rem;
      left: 1rem;
      transform: rotate(0);
      transform-origin: .2rem 50%;
      transition: .25s transform ease;
    }


  }

  .copy {
    color: white;
    text-align: center;
    margin: 2rem;
  }

}

@media only screen and (max-width: 800px) {

  .kontakt {
      padding: 3rem 0;

    .kontakt_feld {


      .row {


        .spalte1 {
          justify-content: flex-start;
          margin: .2rem;
        }
      }
    }
  }

  .footertext {
    width: 100%;
    flex-direction: column !important;
    align-items: center !important;
    details {
      width: 100% !important;

      summary {
        width: 100% !important;
      }
    }
  }

}
