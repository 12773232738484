.datenschutzbanner{
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  background: rgba($green,1);
  position: fixed;
  bottom: 0;
  left: 0;
  color: white;
  padding: 1rem;
  button{
    align-self: center;
    font-size: 1rem;
    color: $green;
    background: white;
    border: none;
    padding: 1rem 2rem;
    margin: 1rem;
  }
  a{
    text-decoration: none;
    color: white;
  }
}
