.header {
  height: 93vh;
  width: 100%;
  position: relative;
  background: linear-gradient(to right,rgba(white,0.5),rgba(black,.2)),url($headerBackground) 110% no-repeat;
  background-size: contain;

  overflow: hidden;

}

.greenbar {
  width: 100%;
  height: 7vh;
  background: $green;
}


@media only screen and (max-width: 1450px) {

  .header {
    background:  linear-gradient(to right,rgba(white,0.5),rgba($green,.5));
  }

}

@media only screen and (max-width: 600px) {

  .header {
    background:  linear-gradient(to right,rgba(white,0.5),rgba($green,.5));
    height: 100vh;

  }
  .greenbar{
    height: 20vh;

  }

}