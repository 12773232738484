.produkt {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-right: 1rem;
  width: 300px;
  height: 300px;

  &:first-child {

  }

  .produktname {
    text-align: center;
    margin: 1rem;
    font-size: 1.1rem;

  }

  .produktbild {
    width: 300px;
    height: 300px;
    border: solid $green 1px;
  }
}


@media only screen and (max-width: 750px) {
  .produkt {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-right: 1rem;
    width: 150px;
    height: 300px;


    &:first-child {

    }

    .produktname {
      text-align: center;
      margin: 1rem;
      font-size: 1.1rem;

    }

    .produktbild {
      width: 150px;
      height: 300px;
      border: solid $green 1px;
    }
  }

}
