//standart
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}
$bild_schleier: linear-gradient(rgba(0, 0, 0, .76), rgba(0, 0, 0, .76));
$bild_schleier_hell: linear-gradient(90deg,rgba(0, 0, 0, .9) 70%, rgba(94, 94, 94,.9));
$bild_schleier_dunkel: linear-gradient(rgba(0, 0, 0, .85), rgba(0, 0, 0, .85));
//$gold: #CC9933;
$gold: #8b7742;



$green : #78b73a;
$darkgreen: #008000;
$black: #231F20;
$braun: #E3C697;
$grey : #707070;



$headerBackground : "../image/header.gif";

.App{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
}


//Component
@import "contactbar";
@import "header";
@import "logo";
@import "dreieck";
@import "vorstellung";
@import "footer";
@import "dreieckmitlogo";
@import "suche";
@import "kategorien";
@import "kategorievorschau";
@import "navigation";
@import "produktkarte";
@import "formPersoenlicheDaten";
@import "kontaktfeld";
@import "datenschutzBanner";




//page
@import "home";
@import "produkte";
@import "kategorie";

//Schrift
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;700&display=swap');
