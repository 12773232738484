.dreieckmitlogo {
  margin-bottom: 5rem;
  position: relative;

  .fläche {
    clip-path: polygon(0 0, 0 100%, 100% 0);
    height: 22vh;
    background: $green;
    opacity: 0.5;

  }
  img {
    position: absolute;
    height: 60%;
    top: 2rem;
    left: 2rem;

  }



}

@media only screen and (max-width: 700px) {
  .dreieckmitlogo {
    height: 50vh;
    position: relative;
    .fläche {
      clip-path: none;
      height: 50vh;
    }
    img {
      position: absolute;
      height: 28vh;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}