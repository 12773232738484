.suche {
  margin-top: -5rem;
  //Suchleiste
  display: flex;
  justify-content: center;
  flex-direction: row;

  .suchbutton {
    width: 4rem;
    background: $green;
    border: none;
    color: white;
    border-bottom-left-radius: 20%;
    border-top-left-radius: 20%;
  }

  .sucheingabe {
    width: 60%;
    padding: 1rem;
    font-size: x-large;
    border: $green solid 1px;
    color: $grey;
  }


}

@media only screen and (max-width: 750px) {

  .suche {
    margin-top: 0;
    width: 100%;

    .suchbutton {
      width: 3rem;
      height: 3rem;
    }

    .sucheingabe {
      width: 80%;
      padding: 0.3rem;
      font-size: 1.2rem;

    }

  }

}



