.kontaktfeld {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  background-size: cover;
  background: $green;
  color: white;
  padding: 3rem;


  h1 {
    font-size: 3rem;
    margin-top: 1rem;

  }

  h2 {
    margin-top: 1rem;
    font-size: 2rem;
    margin-bottom: 2rem;
    padding: .2rem;
    font-weight: normal;
  }

  h3 {
    font-size: 1.5rem;
    margin: 2rem 0;
  }

  h2 {
    margin-bottom: 0.5rem;
  }

  form {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-bottom: 3rem;
    padding: 5rem;
    max-width: 1300px;
    width: 100%;
    background-color: rgba(243, 243, 243, 0.90);

    .felder {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-self: center;
      overflow: hidden;
      width: 100%;
      flex-wrap: wrap;


      .spalte1, .spalte2 {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
        max-width: 500px;
        padding: 2rem;
        color: $green;


        .feld {
          display: flex;
          justify-content: center;
          flex-direction: column;
          width: 100%;
          margin: .5rem 0;
          text-align: start;

          textarea {
            height: 150px !important;
            font-size: 1.3rem !important;
            // color: black !important;
            padding: 1rem 1rem !important;
            //background: rgba(white, .2) !important;
            border: solid 1px $green !important;
          }


          input[type=text], select, textarea {
            height: 3rem;
            font-size: 1.3rem;
            color: $green;
            padding: 0 1rem;
            background-color: rgba(243, 243, 243, 0.90);

            border: solid 1px $green;
            border-radius: 1px;
            margin-right: 1rem;
            background-size: 5px 5px,
            5px 5px,
            1.5em 1.5em;

          }

          input::placeholder, textarea::placeholder {
            color: rgba(102, 171, 86, 0.45);
          }

          label {
            margin: .5rem 0;
            font-size: 1.3rem;
            margin-right: 1rem;
            color: $green;

          }

        }
      }
    }

    button {
      background: $green;
      width: 100%;
      max-width: 700px;
      border: solid 1px white;
      font-size: 1.3rem;
      padding: 1rem;
      margin-bottom: 1rem;
      color: white;
      margin-top: 2rem;
    }
  }
}

@media only screen and (max-width: 800px) {
  .empfehlung {
    h1 {
      font-size: 2.2rem;
    }

    h2 {
      font-size: 1.5rem;
    }

    h3 {
      font-size: 1.4rem;
    }
  }
  button {
    margin: 0 1rem;
  }
  .kontaktfeld {
    padding: 0.1rem;
    form {
      padding: 0;
      width: 100%;

      .felder {
        width: 100%;
      }
    }
  }
}

@media only screen and (max-width: 400px) {
  .empfehlung {
    h1 {
      font-size: 2rem;
    }

    h2 {
      font-size: 1.5rem;
    }

    h3 {
      font-size: 1rem;
      padding: 0.5rem;
    }
  }


}
