.kategorievorschau {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  justify-items: center;
  width: 100%;
  //background: rgba($green,0.3);
  align-self: center;
  justify-self: center;



  .vorschau {
    width: 100%;
    overflow-y: scroll;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .aufbau{
      background: $green;
      padding: 2rem;
      width: 100%;

      h1{
        font-size: 3rem;
        color: white;
      }

      p{
        color: white;
      }
    }


    h1, {
      color: $green;
      font-weight: bold;
      margin-bottom: 1rem;
      text-align: center;
      align-self: center;
      padding: 1rem;

    }
    h3{
      color: white;
      text-align: center;
    }

    h2{
      color: $green;
      text-align: center;
      align-self: center;
      padding: 1rem;

    }
    p {
      color: $grey;

      text-align: center;
      align-self: center;

    }
    .dreieck_links {
      clip-path: polygon(0 0, 0 100%, 100% 0);
      height: 200px;
      width: 100%;
      max-width: 500px;
      background: $green;
      opacity: 0.2;
      position: absolute;
      left: 0;
      z-index: -1;
      display: none;
    }

    .dreieck_rechts {
      clip-path: polygon(0 0, 100% 100%, 100% 0);
      height: 200px;
      width: 100%;
      background: $green;
      opacity: 0.2;
      position: absolute;
      right: 0;
      z-index: -1;
      max-width: 500px;
      display: none;

    }

    .produktreihe {
      display: flex;
      justify-content: center;
      flex-direction: row;
      margin: 2rem 0;
      overflow: scroll;
      width: 100%;
      flex-wrap: wrap ;
      max-width: 1300px;
      align-self: center;
      gap: 1rem;


    }

  }

}


@media only screen and (max-width: 750px) {

  .kategorievorschau {

    .parallelogramm {
      display: none;
    }

    .vorschau {


      .dreieck_links {
        display: block;
      }

      .dreieck_rechts {
        display: block;
      }

      .produktreihe {
        position: relative;
        display: flex;
        justify-content: center;
        flex-direction: row;
        margin: 1rem 0;
        overflow: scroll;
        width: 100%;

      }
    }
  }
}
